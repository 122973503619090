//local
// const BASE_URL = "http://192.168.21.19:5000/node-api/";
// const DEFAULT_KEY = '1339e1c7-2c00-4eda-9a94-c134539a83ce'

const BASE_URL = "http://208.109.247.21:5000/node-api/";
const DEFAULT_KEY = '1339e1c7-2c00-4eda-9a94-c134539a83ce'


const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID }
